import React, { useState } from "react"
import { Button, Input, Radio, Table, message, Modal } from "antd"
import { subDays, eachDayOfInterval, format as dateFormat } from "date-fns"
import Lottie from 'react-lottie-player'
import ChartLine from "@/components/ChartLine"
import tokens from "@/tokens"
import Empty from '@/components/Empty'
import { format } from '@/utils'
import TheoKeys from '@@/resources/lottie/theo-keys.json'
import * as style from "./style.module.scss"

const randowCoeff = Math.random()

const Pools = () => {
  const [tokenFilter, setTokenFilter] = useState('')
  const [showMyPools, setShowMyPools] = useState(false)
  const [positionModal, setPositionModal] = useState(false)

  const filteredPools = tokens
    .filter((token) => showMyPools ? token.share > 0 : true)
    .filter((token) => {
      return token.fingerprint.toLowerCase().includes(tokenFilter) || token.ticker.toLowerCase().includes(tokenFilter)
    })

  const onCopy = () => {
    message.success('Fingerpint copied')
  }

  const columns = [
    {
      width: "350px",
      title: "Pool / TVL",
      dataIndex: "ticker",
      key: "ticker",
      // sorter: (a, b) => a.ticker.localeCompare(b.ticker),
      sorter: (a, b) => a.tvl - b.tvl,
      render: (record, records) => {
        return (
          <div
            className={style.pool}
            onClick={() => setPositionModal(true)}
            onKeyPress={() => setPositionModal(true)}
          >
            <div className={style.poolIcon}>
              <i className="ri ri-settings" />
            </div>
            <div className={style.poolTitle}>
              <div className="mb-1">
                <strong>{record}-ADA</strong>
              </div>
              <div className={style.poolsImages}>
                <img src={`/resources/tokens/${records.img}`} />
                <img src={`/resources/tokens/ada.png`} />
              </div>
            </div>
            <div className={style.poolTvl}>
              <div className="mb-2"><strong>${records.tvl}M</strong></div>
              <div className="font-size-12 text-muted mb-1">{format(records.value)} {records.ticker}</div>
              <div className="font-size-12 text-muted">{format(records.ada)} ADA</div>
            </div>
          </div>
        )
      }
    },
    {
      responsive: ["md"],
      title: "Volume 24h",
      dataIndex: "volume",
      key: "volume",
      sorter: (a, b) => a.tvl - b.tvl,
      render: (record, records) => {
        return (
          <div className="lh-1">
            <div className="mb-2"><strong>${record}M</strong></div>
            <div className="font-size-12 text-muted mb-1">{format(records.value * randowCoeff)} {records.ticker}</div>
            <div className="font-size-12 text-muted">{format(records.ada * randowCoeff)} ADA</div>
          </div>
        )
      }
    },
    {
      responsive: ["lg"],
      title: "Your Share",
      dataIndex: "share",
      key: "share",
      sorter: (a, b) => a.share - b.share,
      render: (record, records) => {
        return (
          <div className="lh-1">
            {record > 0 && (
              <div>
                <div className="mb-2"><strong>{record}%</strong></div>
                <div className="font-size-12 text-muted mb-1">{format(records.value * record)} {records.ticker}</div>
                <div className="font-size-12 text-muted">{format(records.ada * record)} ADA</div>
              </div>
            )}
            {record <= 0 && '—'}
          </div>
        )
      }
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (record, records) => {
        const labels = eachDayOfInterval({ start: subDays(new Date(), 6), end: new Date() }).map((d) => dateFormat(d, 'PP'))
        return (
          <div className="lh-1">
            <div className="mb-1"><strong>{records.price} ADA</strong></div>
            <div style={{ width: 120, height: '2rem', overflow: 'hidden' }}>
              <ChartLine dataset={records.data} labels={labels} height={32} postfix="ADA" />
            </div>
          </div>
        )
      }
    },
  ]

  return (
    <div className="ray__block">
      <Modal
        title={null}
        footer={null}
        visible={positionModal}
        onCancel={() => setPositionModal(false)}
        width={540}
        closeIcon={<span className="ri ri-close" />}
        zIndex={1001}
      >
        <div className="ray__heading mb-3">
          Add Liquidity
        </div>
        <div className="text-center pt-4">
          <h5 className="mb-1">
            <strong>Add Liquidity</strong>
          </h5>
          <div className="text-muted">Adding liquidity will work soon</div>
        </div>
        <div className={style.lottie}>
          <Lottie animationData={TheoKeys} play />
        </div>
        <Button
          className="ant-btn ray__btn ray__btn--large ray__btn--primary w-100"
          onClick={() => setPositionModal(false)}
        >
          <i className="ri ri-add me-2" />
          Add
        </Button>
      </Modal>
      <div className={style.container}>
        <h5 className="d-flex w-100">
          <strong>Liquidity Pools</strong>
          <div className={style.create}>
            <Button 
              className="ray__btn ray__btn--small ray__btn--transparent ms-auto"
              onClick={() => setPositionModal(true)}
            >
              <i className="ri ri-add me-2" />
              <strong>New Position</strong>
            </Button>
          </div>
        </h5>
        <div className="d-flex mb-2">
          <div className="pt-1">
            <Radio.Group
              onChange={(e) => setShowMyPools(e.target.value)}
              value={showMyPools}
            >
              <Radio value={false}>All</Radio>
              <Radio value={true}>My Positions</Radio>
            </Radio.Group>
          </div>
          <div className="ms-auto">
            <Input
              size="default"
              placeholder="Search pool..."
              allowClear
              onChange={(e) => setTokenFilter(e.target.value.toLowerCase())}
            />
          </div>
        </div>
        {!!filteredPools.length && (
          <div className={`ray__table ${style.poolsContainer}`}>
            <Table
              showSorterTooltip={false}
              rowKey="fingerprint"
              dataSource={filteredPools}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        {!filteredPools.length && <Empty title="No Tokens Found" message="Try another ticker name or fingerprint" />}
      </div>
    </div>
  )
}

export default Pools
